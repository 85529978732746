import React, { useRef } from "react"
import Seo from '../components/seo'
import Layout from "../components/layout"
import { FormattedMessage } from "react-intl"
import {
  Player,
  ControlBar,
  PlayToggle, // PlayToggle 播放/暂停按钮 若需禁止加 disabled
  ReplayControl, // 后退按钮
  ForwardControl,  // 前进按钮
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,  // 倍速播放选项
  VolumeMenuButton
} from 'video-react';
import "video-react/dist/video-react.css"; // import css

import '../style/feature.scoped.scss'

const Feature = ({ pageContext }) => {
  const pageLang = pageContext.lang
  const pagePrefix = pageLang === 'en' ? '/en' : ''
  const player1 = useRef(null)
  const player2 = useRef(null)
  const player3 = useRef(null)

  function changeStatus() {
    player1.current.state = true
    player2.current.video.pause()
    player3.current.video.pause()
  }
  function changeStatus2() {
    player2.current.state = true
    player1.current.video.pause()
    player3.current.video.pause()
  }
  function changeStatus3() {
    player3.current.state = true
    player2.current.video.pause()
    player1.current.video.pause()
  }

  return (
    <Layout pageLang={pageLang} showHeader={true} showFooter={true} useTransparentTheme={false}>
      <Seo title="MogDB: New Feature" />
      <div className="feature-container">
        <div className={`banner ${pageLang === 'en' ? 'banner-en' : ''}`}>
          <p className="title"><FormattedMessage id="feature.title" /></p>
          <div className="desc">
            <p><FormattedMessage id="feature.titleDesc" /></p>
            <p><FormattedMessage id="feature.titleDesc1" /></p>
          </div>
          <div className="line"></div>
          <i></i>
          <div className="line right"></div>
        </div>
        <div className="features">
          <div className="box box-6">
            <div>
              <div className="text">
                <div className="title">
                  <FormattedMessage id="feature.feature6Title" />
                </div>
                <div className="content">
                  <p><FormattedMessage id="feature.feature6Desc" /></p>
                  <p><FormattedMessage id="feature.feature6Desc1" /></p>
                  <p><FormattedMessage id="feature.feature6Desc2" /></p>
                </div>
              </div>
              <div className="image" onClick={changeStatus}>
                <Player
                  ref={player1}
                  className="player-box"
                  autoPlay={false}
                  playsInline
                  src="https://cdn-mogdb.enmotech.com/website/searchBuildUP.mp4"
                  poster="https://cdn-mogdb.enmotech.com/website/searchBuildUP.png"
                >

                  <ControlBar autoHide={false} disableDefaultControls={false}>
                    {/* <ReplayControl seconds={10} order={1.1} /> */}
                    {/* <PlayToggle /> */}
                    {/* <CurrentTimeDisplay order={4.1} /> */}
                    {/* <TimeDivider order={4.2} /> */}
                    {/* <PlaybackRateMenuButton rates={[5, 2, 1.5, 1, 0.5]} order={7.1} /> */}
                    {/* <VolumeMenuButton /> */}
                  </ControlBar>
                </Player>
              </div>
            </div>
          </div>
          <div className="box box-7">
            <div>
              <div className="image" onClick={changeStatus2}>
                <Player
                  ref={player2}
                  className="player-box"
                  autoPlay={false}
                  playsInline
                  src="https://cdn-mogdb.enmotech.com/website/toolBuildUp.mp4"
                  poster="https://cdn-mogdb.enmotech.com/website/toolBuildUp.png"
                >

                  <ControlBar autoHide={false} disableDefaultControls={false}>
                    <ReplayControl seconds={10} order={1.1} />
                    <PlayToggle />
                    <CurrentTimeDisplay order={4.1} />
                    <TimeDivider order={4.2} />
                    <PlaybackRateMenuButton rates={[5, 2, 1.5, 1, 0.5]} order={7.1} />
                    <VolumeMenuButton />
                  </ControlBar>
                </Player>
              </div>
              <div className="text">
                <div className="title">
                  <FormattedMessage id="feature.feature7Title" />
                </div>
                <div className="content">
                  <p><FormattedMessage id="feature.feature7Desc" /></p>
                </div>
              </div>
            </div>
          </div>
          <div className="box box-8">
            <div>
              <div className="text">
                <div className="title">
                  <FormattedMessage id="feature.feature8Title" />
                </div>
                <div className="content">
                  <p><FormattedMessage id="feature.feature8Desc" /></p>
                </div>
              </div>
              <div className="image" onClick={changeStatus3}>
                <Player
                  ref={player3}
                  className="player-box"
                  autoPlay={false}
                  playsInline
                  src="https://cdn-mogdb.enmotech.com/website/cmDeploy.mp4"
                  poster="https://cdn-mogdb.enmotech.com/website/cmDeploy.png"
                >

                  <ControlBar autoHide={false} disableDefaultControls={false}>
                    <ReplayControl seconds={10} order={1.1} />
                    <PlayToggle />
                    <CurrentTimeDisplay order={4.1} />
                    <TimeDivider order={4.2} />
                    <PlaybackRateMenuButton rates={[5, 2, 1.5, 1, 0.5]} order={7.1} />
                    <VolumeMenuButton />
                  </ControlBar>
                </Player>
              </div>
            </div>
          </div>
          <div className="box box-1">
            <div>
              <div className="image"><i></i></div>
              <div className="text">
                <div className="title">
                  <FormattedMessage id="feature.feature4Title" />
                </div>
                <div className="content">
                  <p><FormattedMessage id="feature.feature4Desc" /></p>
                  <p><FormattedMessage id="feature.feature4Desc1" /></p>
                  <a href={`https://docs.mogdb.io/${pageLang}/mogdb/latest/19-transaction-async-submit`} target="_blank"><FormattedMessage id="feature.link" /></a>
                </div>
              </div>
            </div>
          </div>
          <div className="box box-2">
            <div>
              <div className="text">
                <div className="title">
                  <FormattedMessage id="feature.feature5Title" />
                </div>
                <div className="content">
                  <p><FormattedMessage id="feature.feature5Desc" /></p>
                  <a href={`https://docs.mogdb.io/${pageLang}/mogdb/latest/unified-database-management-tool`} target="_blank"><FormattedMessage id="feature.link2" /></a>
                </div>
              </div>
              <div className="image"><i></i></div>
            </div>
          </div>
          <div className="box box-3">
            <div>
              <div className="image"><i></i></div>
              <div className="text">
                <div className="title">
                  <FormattedMessage id="feature.feature1Title" />
                </div>
                <div className="content">
                  <p><FormattedMessage id="feature.feature1Desc" /></p>
                  <p><FormattedMessage id="feature.feature1Desc1" /></p>
                  <a href={`https://docs.mogdb.io/${pageLang}/mogdb/latest/21-dynamic-partition-pruning`} target="_blank"><FormattedMessage id="feature.link3" /></a>
                </div>
              </div>
            </div>
          </div>
          <div className="box box-4">
            <div>
              <div className="text">
                <div className="title">
                  <FormattedMessage id="feature.feature2Title" />
                </div>
                <div className="content">
                  <p><FormattedMessage id="feature.feature2Desc" /></p>
                  <div>
                    <p><FormattedMessage id="feature.feature2Desc1" /></p>
                    <p><FormattedMessage id="feature.feature2Desc2" /></p>
                    <p><FormattedMessage id="feature.feature2Desc3" /></p>
                  </div>
                  <a href={`https://docs.mogdb.io/${pageLang}/mogdb/latest/PostGIS`} target="_blank"><FormattedMessage id="feature.link4" /></a>
                </div>
              </div>
              <div className="image"><i></i></div>
            </div>
          </div>
          <div className="box box-5">
            <div>
              <div className="image"><i></i></div>
              <div className="text">
                <div className="title">
                  <FormattedMessage id="feature.feature3Title" />
                </div>
                <div className="content">
                  <p><FormattedMessage id="feature.feature3Desc" /></p>
                  <p><FormattedMessage id="feature.feature3Desc1" /></p>
                  <a href={`https://docs.mogdb.io/${pageLang}/mogdb/latest/3.0.0`} target="_blank"><FormattedMessage id="feature.link5" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn-group">
          <a rel="noreferrer" href={`${pagePrefix}/mogdb/`} className="btn more"><FormattedMessage id="feature.learnMogdb" /></a>
          <a href={`https://docs.mogdb.io/${pageLang}`} target="_blank" className="btn searchDoc"><FormattedMessage id="feature.viewDoc" /></a>
        </div>
      </div>
    </Layout>
  )
}
export default Feature